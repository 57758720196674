import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { concatMap, delay, tap } from 'rxjs/operators';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { Device } from '../models/device/device';
import { Product } from '../models/device/product';
import { Household } from '../models/household/household';
import { ServerResponse } from '../models/http/server-response';
import { ServerResponseCollection } from '../models/http/server-response-collection';
import { HttpService } from './http/http.service';

@Injectable({ providedIn: 'root' })
export class DeviceService extends HttpService<Device> {
  private pollHubRequiresUpdateStatusSubject = new BehaviorSubject<boolean>(
    true,
  );
  public pollHubRequiresUpdateStatus = this.pollHubRequiresUpdateStatusSubject.pipe(
    // Wait for http call response time + 1000ms
    delay(1000),
    concatMap(() => this.checkFirmwareUpdate()),
    tap(
      () => {
        this.pollHubRequiresUpdateStatusSubject.next(true);
      },
      (err) => {
        this.pollHubRequiresUpdateStatusSubject.next(true);
      },
    ),
  );

  constructor(http: HttpClient) {
    super(http, 'devices', 'api/device');
    this.httpParams = {
      'with[]': ['children', 'tags', 'control', 'status'],
    };
  }

  allForHousehold(household: Household | number): Device[] {
    const householdId = this.getId(household);
    return this.all().filter((p) => p.household_id === householdId) || [];
  }

  allForHouseholdByType(
    household: Household | number,
    product: Product | number,
  ): Device[] {
    const householdId = this.getId(household);
    const productId = this.getId(product);
    return (
      this.allForHousehold(household).filter(
        (p) => p.product_id === productId,
      ) || []
    );
  }

  allForHouseholdAsync(
    household: Household | number,
    reload = false,
  ): Promise<Device[]> {
    const householdId = this.getId(household);
    return this.allAsync(reload).then(
      (result) =>
        result.filter((p) => p.household_id === householdId) || [],
    );
  }

  allForHouseholdByTypeAsync(
    household: Household | number,
    product: Product | number,
    reload = false,
  ): Promise<Device[]> {
    const productId = this.getId(product);
    return this.allForHouseholdAsync(household, reload).then(
      (result) => result.filter((p) => p.product_id === productId) || [],
    );
  }

  getHubForHousehold(household: Household | number): Device {
    const householdId = this.getId(household);
    const result = this.allForHousehold(householdId).filter(
      (d) => d.product_id === ProductTypeEnum.Hub,
    )[0];
    return typeof result === 'undefined' ? null : result;
  }

  getForHousehold(household: Household | number, id): Device {
    const householdId = this.getId(household);
    return this.filterById(this.allForHousehold(householdId), id);
  }

  getForHouseholdAsync(
    household: Household | number,
    id: number,
    reload = false,
  ): Promise<Device> {
    const householdId = this.getId(household);
    return this.allForHouseholdAsync(householdId, reload).then((result) =>
      this.filterById(result, id),
    );
  }

  allPairingAsync(): Promise<Device[]> {
    return this.http
    .get<ServerResponseCollection<Device>>(`${this.getUrl()}/pairing`)
    .toPromise()
    .then(
      (result) => {
        return result.data;
      },
      (err) => {
        this.tryHandleError(err);
        throw err;
      },
    );
  }

  pairAsync(device: Device, household: Household | number): Promise<Device> {
    const deviceId = this.getId(device);
    const householdId = this.getId(household);
    return this.http
    .post(`${this.getUrl()}/${deviceId}/pair/${householdId}`, null)
    .toPromise()
    .then(
      (result) => {
        device.household_id = householdId;
        return this.assignById(device);
      },
      (err) => {
        this.tryHandleError(err);
        throw err;
      },
    );
  }

  triggerLearnMode(device: Device, stopDevice = true): Promise<Device> {
    const deviceId = this.getId(device);
    return this.http.put(`${this.getUrl()}/${deviceId}/control`, { learn_mode: stopDevice }).toPromise().then((result) => {
      return this.assignById(device);
    }, (err) => {
      this.tryHandleError(err);
      throw err;
    });
  }

  checkFirmwareUpdate(): Promise<boolean> {
    return this.http
    .get<ServerResponse<any>>(`${this.getUrl()}/needs-update`)
    .toPromise()
    .then(
      (result) => {
        return result.data.needs_manual_update;
      },
      (err) => {
        this.tryHandleError(err);
        throw err;
      },
    );
  }
}
