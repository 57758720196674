import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '../../../core/models/http/server-response';
import { Country } from '../../../core/models/locale/country';
import { HttpService } from '../../../core/services/http/http.service';

export const defaultCountryCode = 77;
export const usaCountryCode = 233;

@Injectable({ providedIn: 'root' })
export class CountryService extends HttpService<Country> {
    private selected: string;

    constructor(http: HttpClient) {
        super(http, 'countries', 'api/country');
    }

    getCurrentOrDefault() {
        return this.getByCurrentCode() || this.getDefault();
    }

    getDefault(): Country {
        return this.get(defaultCountryCode);
    }

    getCurrentCode(): string {
        return this.selected;
    }

    setCurrentCode(code: string) {
        this.selected = code;
    }

    getByCurrentCode(): Country {
        return this.getByCode(this.getCurrentCode());
    }

    getByCode(code): Country {
        const result = this.all().filter((p) => p.code === code)[0];
        return typeof result === 'undefined' ? null : result;
    }

    getByLanguageId(id: number): Observable<ServerResponse<Country[]>> {
        let params = new HttpParams();

        params = params.set('language_id', String(id));

        return this.http.get<ServerResponse<Country[]>>(this.getUrl(), { params });
    }
}
