import { Pipe, PipeTransform } from '@angular/core';
import { WeightConversionService } from '../../services/utility/weight-conversion.service';

@Pipe({
  name: 'weightSmall',
})
export class WeightSmallPipe implements PipeTransform {
  constructor(private weightConversionService: WeightConversionService) {}

  transform(gramAmount: number, isWeight: boolean = true) {
    return (
      this.weightConversionService.convert(gramAmount, isWeight) +
      this.weightConversionService.getShortUnit()
    );
  }
}
