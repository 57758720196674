import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UnauthorisedError } from '../../models/http/errors/unauthorised.error';
import { HttpErrorType } from '../../models/http/errors/http-error.type';
import { NoResponseError } from '../../models/http/errors/no-response.error';
import { ServerError } from '../../models/http/errors/server.error';
import { environment } from '../../../../environments/environment';
import { IModel } from '../../models/model';

export class BaseHttpService {
  static errorEvent = new Subject<HttpErrorType>();
  httpParams: any;

  static fireErrorEvent(error: HttpErrorType) {
    BaseHttpService.errorEvent.next(error);
  }

  constructor(protected http: HttpClient, protected apiUrl: string) {
    this.httpParams = [];
  }

  getHttpGetOptions() {
    return this.httpParams ? { params: this.httpParams } : null;
  }

  getUrl(): string {
    return `${environment.apiUrl}/${this.apiUrl}`;
  }

  tryHandleError(err) {
    if (!err) {
      return;
    }

    console.error(`error in ${this.apiUrl}`, err);
    switch (err.status) {
      case -1:
      case 0: {
        BaseHttpService.fireErrorEvent(new NoResponseError(this.apiUrl, err));
        break;
      }
      case 401: {
        BaseHttpService.fireErrorEvent(new UnauthorisedError(this.apiUrl, err));
        return true;
      }
      case 500:
      case 503:
      case 504: {
        BaseHttpService.fireErrorEvent(
          new ServerError(err.status, this.apiUrl, err)
        );
        break;
      }
    }

    return false;
  }

  protected getId<T extends IModel>(object: T | number): number {
    return typeof object === 'number' ? object : object?.id;
  }
}
