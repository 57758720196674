import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAuthService } from '../services/auth/base-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: BaseAuthService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const setHeaders = {};

    // setHeaders['X-App-Version'] = platform.app.version;
    // setHeaders['X-Device-Id'] = platform.device.uuid;
    const token = this.authService.getToken();
    if (token) {
      setHeaders['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('AuthInterceptor API request before token ready', req.url);
    }
    req = req.clone({ setHeaders });
    return next.handle(req);
  }
}
