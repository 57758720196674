import { Injector } from '@angular/core';
import { ProductTypeEnum } from '@core/enum/product-type.enum';
import { AppInjector } from '@core/services/injector';
import { DeviceService } from '@core/services/device.service';
import { HouseholdService } from '@core/services/household.service';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import * as _ from 'lodash';

export class Helper {
  /* Fixing viwport problem when keyboard open up*/
  static fixIOS12() {
    let inputYoffset = 0;
    $(document).on(
      'focus',
      'input:not([type=submit]), textarea, select',
      () => {
        inputYoffset = window.pageYOffset;
      }
    );
    $(document).on('blur', 'input:not([type=submit]), textarea, select', () => {
      document.getElementsByTagName('html')[0].style.height = '101vh';
      setTimeout(() => {
        document.getElementsByTagName('html')[0].style.height = '100vh';
        window.scrollTo(0, 0);
        window.scrollTo(0, inputYoffset);
      }, 50);
    });
  }

  /* Detecting ios12 version */
  static iOS12Version(): boolean | number {
    const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match !== undefined && match !== null) {
      return parseFloat(match[1]);
    }

    return false;
  }

  static checkAppMobile() {
    // @ts-ignore
    return this.hasWrapperMethod('InWrapper') ? Wrapper.InWrapper() : false;
  }

  static hasWrapper() {
    // @ts-ignore
    return typeof Wrapper !== 'undefined';
  }

  static hasWrapperMethod(method) {
    if (!this.hasWrapper()) {
      return false;
    }

    // @ts-ignore
    return typeof Wrapper[method] === 'function';
  }

  static rangeSmooth(num) {
    const val = parseFloat(num);
    const threshhold = 0.25;

    if (
      val - Math.floor(val) > threshhold &&
      Math.ceil(num) - val > threshhold
    ) {
      return Math.floor(val) + '-' + Math.ceil(val);
    } else {
      return Math.round(val);
    }
  }

  // pretty time strings:
  static zeroPad(num) {
    if (num >= 0) {
      return (num < 10 ? '0' : '') + num;
    }

    return num;
  }

  static zeroPadAMPM(time) {
    const separator = time.split(':');
    const num = parseInt(separator[0], 10);

    if (num >= 0 && num < 10) {
      return ['0' + num, separator[1]].join(':');
    }

    return time;
  }

  static convertSecondToFormat(amount): ConvertSecondToFormatResult {
    const d = Number(amount);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const translator = AppInjector.injector.get(TranslateService);

    let objResult: ConvertSecondToFormatResult = {
      time: '',
      unit: '',
    };

    if (h > 0) {
      objResult = {
        time: `${Helper.zeroPad(h)}:${Helper.zeroPad(m)}`,
        unit: translator.instant('TIME_H_UNIT'),
      };
    } else if (m > 0) {
      objResult = {
        time: `${Helper.zeroPad(m)}:${Helper.zeroPad(s)}`,
        unit: translator.instant('TIME_MINS_UNIT'),
      };
    } else if (s >= 0) {
      objResult = {
        time: `${Helper.zeroPad(s)}`,
        unit: translator.instant('TIME_S_UNIT'),
      };
    }

    return objResult;
  }

  static convertSecondToHours(amount): string {
    const d = Number(amount);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);

    if (h > 0) {
      return `${Helper.zeroPad(h)}:${Helper.zeroPad(m)}`;
    } else if (m > 0) {
      return `00:${Helper.zeroPad(m)}`;
    }

    // TODO Implement second logic
    return '00:00';
  }

  static petHasDevices(deviceTypes, pet) {
    const deviceService = AppInjector.injector.get(DeviceService);
    const householdService = AppInjector.injector.get(HouseholdService);
    deviceTypes = deviceTypes
      ? _.isArray(deviceTypes)
        ? deviceTypes
        : [deviceTypes]
      : Object.keys(ProductTypeEnum).filter((v) => _.isNumber(v)); // All (need number filter as TS enums also map backwards)
    const deviceTypesMap = _.pick(ProductTypeEnum, deviceTypes);

    const devices = deviceService.allForHousehold(
      householdService.getCurrent()['id']
    );
    for (const item of devices) {
      if (
        deviceTypesMap[item.product_id] &&
        item.tags &&
        item.tags.length > 0
      ) {
        for (const tag of item.tags) {
          if (tag.id === pet.tag_id) {
            return true;
          }
        }
      }
    }
  }
}

class ConvertSecondToFormatResult {
  time: string;
  unit: string;
}
