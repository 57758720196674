import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../../models/timeline/user';

@Injectable({ providedIn: 'root' })
export class BaseAuthService {
  logoutEvent = new Subject<{
    requested: boolean;
    redirect: boolean;
    redirectTo: string;
  }>();
  private _user: User;
  private _token: string;

  constructor() {
    this._user = null;
    this._token = null;
  }

  isAuthenticated(): boolean {
    return this.getToken() ? true : false;
  }

  getUser(): User {
    return this._user;
  }

  getToken(): string {
    return this._token;
  }

  assignUser(user: User) {
    this._user = user;
  }

  assignToken(token: string) {
    this._token = token;
  }
}
