import { Pipe, PipeTransform } from '@angular/core';
import { AppInjector } from '@core/services/injector';
import { TimeFormatEnum } from '../../../core/enum/time-format.enum';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {TranslateService} from '@ngx-translate/core';
import {Helper} from '../../global/helper';
import {LanguageService} from '../../services/http/language.service';

@Pipe({name: 'formatTime'})

export class FormatTimePipe implements PipeTransform {

    constructor() { }

    // TODO: just use momentjs.... (see 'timeToMoment' below)
    private static zeroPad = (num) => num <= 9 ? '0' + num : num;       // because parsing turns '00' => 0

    static toAMPM(time: string) {
        const translator = AppInjector.injector.get(TranslateService);
        const translation = `(${translator.instant('TIME_AM_UNIT')}|${translator.instant('TIME_PM_UNIT')})`;
        const ampmRegex = new RegExp(translation, 'i');
        let result = '';

        if (time) {
            if (time.match(ampmRegex)) {
                result = time;
            } else {
                const timeParts = time.trim().split(':').map(parseFloat);
                switch (timeParts[0]) {
                    case 0:
                        result =  '12:' + this.zeroPad(timeParts[1]) + ' ' +  translator.instant('TIME_AM_UNIT');
                        break;
                    case 12:
                        result = `${time} ${translator.instant('TIME_PM_UNIT')}`;
                        break;
                    default:
                        // eslint-disable-next-line max-len
                        result = (timeParts[0] < 12) ? `${time} ${translator.instant('TIME_AM_UNIT')}` : this.zeroPad(timeParts[0] - 12) + ':' + this.zeroPad(timeParts[1]) + ' ' +  translator.instant('TIME_PM_UNIT');
                        break;

                }
            }
        }

        return result;
    }

    static to24H(time: string) {
        if (time) {
            const translator = AppInjector.injector.get(TranslateService);
            let translation = `(${translator.instant('TIME_AM_UNIT')})|(${translator.instant('TIME_PM_UNIT')})`
                .replace(/\./gi, '\\.')
                .replace(/\s/gi, '\\s');

            // HAck to fix the IOS Dutch issue (problem not taking the right units format a.m. / p.m. and instead AM / PM)
            const forceAMPM = Helper.iOS12Version() &&  AppInjector.injector.get(LanguageService).getCurrentCode() === 'nl';
            translation = forceAMPM ? '(AM)|(PM)' : translation;

            const ampmRegex = new RegExp(translation, 'i');
            const amOrPm = time.match(ampmRegex);
            const timeParts = time.trim().replace(ampmRegex, '').split(/[.:]/).map(parseFloat);

            if (!amOrPm) {
                return this.zeroPad(timeParts[0] === 24 ? 0 : timeParts[0]) + ':' + this.zeroPad(timeParts[1]);
            } else {
                // HAck to fix the IOS Dutch issue (problem not taking the right units format a.m. / p.m. and instead AM / PM)
                const pm = forceAMPM ? 'PM' : translator.instant('TIME_PM_UNIT').replace(/\s/g, '');
                const am = forceAMPM ? 'AM' : translator.instant('TIME_AM_UNIT').replace(/\s/g, '');

                const offset =  ((amOrPm[0].replace(/\s/g, '') === pm && timeParts[0] < 12) ||
                                (amOrPm[0].replace(/\s/g, '') === am && timeParts[0] === 12)) ?
                                12 : 0;

                const result =
                    this.zeroPad((timeParts[0] + offset) % 24) +
                    ':' +
                    this.zeroPad(timeParts[1]);
                return result;
            }
        }
    }

    transform(time: any, to: TimeFormatEnum) {
        if (_.isNumber(time)) {
            time = time + '';
        }
        switch (true) {
            case to === TimeFormatEnum.AMPM:
                return FormatTimePipe.toAMPM(time);
            case to === TimeFormatEnum.ISO:
                return FormatTimePipe.to24H(time);
            default:
                return time;
        }
    }

    // TODO use moment.js....
    timeToMoment(time: string) {
        if (time && time.split(':').length > 1) {
            const timeUnits = _.zipObject(['hours', 'minutes'], time.split(':'));
            return moment().set( timeUnits );
        }
    }

    static convertCatFlapLeftEnteredToFormat(
      timeFormat: TimeFormatEnum,
      startTime: string,
      endTime: string,
      currentTime: string
    ): { start: string; end: string; startPlaceholder: string; endPlaceholder: string; } {
        const start = FormatTimePipe.timeToFormat(startTime, timeFormat);
        const end = FormatTimePipe.timeToFormat(endTime, timeFormat);

        const startPlaceholder = startTime === '00:00' ? '-' : start;
        const endPlaceholder =
          endTime === '23:59' ? '-' : currentTime === endTime ? '--:--' : end;

        return { startPlaceholder, endPlaceholder, end, start };
    }

    static timeToFormat(time: string, timeFormat: TimeFormatEnum): string {
        const format = timeFormat || TimeFormatEnum.ISO;

        return format === TimeFormatEnum.AMPM
          ? FormatTimePipe.toAMPM(Helper.zeroPad(time))
          : Helper.zeroPad(time);
    }
}
