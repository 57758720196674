import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { FormatTimePipe } from '../../shared/pipes/format-time/format-time.pipe';
import { WeightSmallPipe } from '../../shared/pipes/weight/weight-small.pipe';
import { WeightPipe } from '../../shared/pipes/weight/weight.pipe';

@NgModule({
  declarations: [
    LoaderComponent,
    WeightPipe,
    WeightSmallPipe,
    FormatTimePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HammerModule,
    TranslateModule,
    HighchartsChartModule,
  ],
  exports: [
    LoaderComponent,
    WeightPipe,
    WeightSmallPipe,
    FormatTimePipe,
  ],
})
export class UtilityModule {
}
