import { Injectable } from '@angular/core';
import { ReportStatsActivityModel } from '@core/models/report/stats/report-stats';
import { TranslateService } from '@ngx-translate/core';
import * as Qty from 'js-quantities';
import * as _ from 'lodash';
import { WeightUnitsEnum } from '../../../core/enum/weight-units.enum';
import { Country } from '../../../core/models/locale/country';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CountryService } from '../http/country.service';

@Injectable({ providedIn: 'root' })
export class WeightConversionService {
  constructor(
    private authService: AuthService,
    private $translate: TranslateService,
    private countryService: CountryService,
  ) {
  }

  getUserSetting(): WeightUnitsEnum {
    return this.authService.getUser().weight_units || WeightUnitsEnum.Gram;
  }

  getUserCountry(): Country {
    const user = this.authService.getUser();
    return this.countryService.get(user.country_id) || null;
  }

  highUnitToGrams(value: number) {
    if (this.getUserSetting() === WeightUnitsEnum.Gram) {
      return Qty(value, 'kg').to('g').scalar;
    } else {
      return Qty(value, 'lbs').to('g').scalar;

    }
  }

  toGrams(value: number, unit: string = null): number {
    if (value === null) {
      return value;
    }
    return this.toGramsInternal(value, unit).scalar;
  }

  convert(value: number, isWeight: boolean = true): number {
    return this.convertInternalSmallestUnit(value, isWeight).scalar;
  }

  public convertInternal(value: number, isWeight: boolean = true, showOnlyValue: boolean = false): Qty[] {
    const unit = Qty(value, isWeight ? 'g' : 'ml');
    let total;

    switch (this.getUserSetting()) {
      case WeightUnitsEnum.Gram: {
        total = isWeight
          ? this.convertInternalMetricsWeight(value, unit, showOnlyValue)
          : this.convertInternalMetricsVolumnen(value, unit);
        break;
      }
      case WeightUnitsEnum.Oz: {
        total = isWeight
          ? this.convertInternalImperialsWeight(value, unit, showOnlyValue)
          : this.convertInternalImperialsVolumnen(value, unit);
        break;
      }
    }
    return total;
  }

  convertWithUnits(value: number, isWeight: boolean = false) {
    return this.convertInternal(value, isWeight)
    .map((q) => q.format(this.configurableRoundingFormatter()))
    .join(' ');
  }

  getRightHigherUnit(value: number, isWeight: boolean = false) {
    let unit = '';
    this.convertInternal(value, isWeight, true).map((q, idx) => {
      if (idx === 0) {
        q.format((scalar, units) => {
          unit = this.getTranslationUnit(units);
        });
      }
    });

    return unit;
  }

  getShortUnit(isWeight: boolean = true): string {
    switch (this.getUserSetting()) {
      case WeightUnitsEnum.Gram:
        return isWeight
          ? this.$translate.instant('UNIT_WEIGHT_GR')
          : this.$translate.instant('FLUID_ML_UNIT');
      case WeightUnitsEnum.Oz:
        return isWeight
          ? this.$translate.instant('WEIGHT_OZ_UNIT')
          : this.$translate.instant('FLUID_FLOZ_UNIT');
    }

    return null;
  }

  getHighUnit(isWeight: boolean = true): string {
    switch (this.getUserSetting()) {
      case WeightUnitsEnum.Gram:
        return isWeight
          ? this.$translate.instant('UNIT_WEIGHT_KG')
          : this.$translate.instant('FLUID_FLOZ_UNIT');
      case WeightUnitsEnum.Oz:
        return isWeight
          ? this.$translate.instant('WEIGHT_LBS_UNIT')
          : this.$translate.instant('FLUID_FLOZ_UNIT');
    }

    return null;
  }

  getWetAndDry(data: ReportStatsActivityModel): { dryWeight: number; wetWeight: number } {
    let dryWeight = 0;
    let wetWeight = 0;

    if (data.bowl_count === 2) {
      _.each(data.weights, function (weight) {
        if (weight.food_type_id === 1) {
          wetWeight += Math.abs(weight.change);
        }
        if (weight.food_type_id === 2) {
          dryWeight += Math.abs(weight.change);
        }
      });
    } else if (data.bowl_count === 1) {
      if (data.weights[0].food_type_id === 1) {
        wetWeight += Math.abs(data.weights[0].change);
      } else if (data.weights[0].food_type_id === 2) {
        dryWeight += Math.abs(data.weights[0].change);
      }
    }

    return {
      dryWeight,
      wetWeight,
    };
  }

  private toGramsInternal(value: number, unit: string = null): Qty {

    try {
      if (unit) {
        return Qty(value, unit).to('g');
      }
      if (this.getUserSetting() === WeightUnitsEnum.Gram) {
        return Qty(value, 'g');
      }

    } catch {
      if (this.getUserSetting() === WeightUnitsEnum.Gram) {
        return Qty(value, 'g');
      }
    }

    return Qty(value, 'oz').to('g');
  }

  private convertInternalSmallestUnit(value: number, isWeight: boolean): Qty {
    const unitMeasurement = isWeight ? 'g' : 'ml';
    const unitImperialMeasurement = isWeight ? 'oz' : 'floz';
    const unit = Qty(value, unitMeasurement);

    if (this.getUserSetting() === WeightUnitsEnum.Gram) {
      return unit.toPrec(unitMeasurement);
    }

    return unit.to(unitImperialMeasurement).toPrec(0.01);
    // return unit.to(unitImperialMeasurement).toFixed(2);
  }

  private configurableRoundingFormatter() {
    return (scalar, units) => {
      units = this.getTranslationUnit(units);
      return scalar + ' ' + units;
    };
  }

  private convertInternalMetricsWeight(value, unit: Qty, showOnlyKg: boolean = false): Qty[] {
    if (value >= 500 || showOnlyKg) {
      return [unit.to('kg').toPrec(0.01)];
    }
    return [unit.toPrec('g')];
  }

  private convertInternalImperialsWeight(value, unit: Qty, showOnlyLbs: boolean): Qty[] {

    if (showOnlyLbs) {
      return [unit.to('lb').toPrec(0.01)];
    }

    const wholeLbs = unit.to('lb');
    const lbs = Qty(Math.floor(wholeLbs.scalar), 'lb').toPrec(0.01);
    const diffForOz = wholeLbs.scalar - lbs.scalar;
    const oz = Qty(diffForOz, 'lb').to('oz').toPrec(0.01);

    if (lbs.scalar === 0) {
      return [oz];
    }

    return [lbs, oz];
  }

  private convertInternalMetricsVolumnen(value, unit: Qty): Qty[] {
    if (value >= 1000) {
      return [unit.to('l').toPrec(0.01)];
    }
    return [unit.toPrec('ml')];
  }

  private convertInternalImperialsVolumnen(value, unit: Qty): Qty[] {
    const isUSA = this.getUserCountry().code === 'us';
    const unitMediumCountry = isUSA ? 'qt' : 'pt';

    // Internal function to calculate USA Imperial
    const usaOzFormat = (): Qty => (scalar, units): Qty => {
      const convertionProportion = { gal: 0.832674, floz: 1.04084 };
      const check = Qty(
        scalar *
        (!isUSA && convertionProportion[units]
          ? convertionProportion[units]
          : 1),
        units,
      );
      return check;
    };

    const wholeGls = unit.format('gal', usaOzFormat());
    const gls = Qty(Math.floor(wholeGls.scalar), 'gal');
    const diffForQt = wholeGls.scalar - gls.scalar;

    const qtsAll = Qty(diffForQt, 'gal').to(unitMediumCountry);
    const qts = Qty(Math.floor(qtsAll.scalar), unitMediumCountry);

    // If UK; it will be pt and therefore we pass it back to US (*1.2)
    const diffForOz =
      (qtsAll.scalar - qts.scalar) *
      (unitMediumCountry === 'pt' ? 1.2 : 1);

    const oz = Qty(diffForOz, unitMediumCountry)
    .format('floz', usaOzFormat())
    .toPrec(0.01);

    if (gls.scalar === 0 && qts.scalar === 0) {
      return [oz];
    } else if (gls.scalar === 0) {
      return [qts, oz];
    } else {
      return [gls, qts, oz];
    }
  }

  private getTranslationUnit(units: string): string {
    switch (units) {
      case 'oz':
        units = this.$translate.instant('WEIGHT_OZ_UNIT');
        break;
      case 'lbs':
        units = this.$translate.instant('WEIGHT_LBS_UNIT');
        break;
      case 'floz':
        units = this.$translate.instant('FLUID_FLOZ_UNIT');
        break;
      case 'ml':
        units = this.$translate.instant('FLUID_ML_UNIT');
        break;
      case 'kg':
        units = this.$translate.instant('UNIT_WEIGHT_KG');
        break;
      case 'g':
        units = this.$translate.instant('UNIT_WEIGHT_GR');
        break;
    }
    return units;
  }

}
