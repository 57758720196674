import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from '../../../core/models/locale/language';
import { HttpService } from '../../../core/services/http/http.service';

@Injectable({ providedIn: 'root' })
export class LanguageService extends HttpService<Language> {
  private selected: string;
  private disabledLanguages = [134];

  constructor(http: HttpClient) {
    super(http, 'languages', 'api/language');
  }

  getCurrentOrDefault(): Language {
    return this.getByCurrentCode() || this.getDefault();
  }

  getDefault(): Language {
    const language_default = this.getByCode(
      window.navigator.language.slice(0, 2),
    );
    return language_default ? language_default : this.get(37);
  }

  getCurrentCode(): string {
    return this.selected;
  }

  setCurrentCode(code: string) {
    this.selected = code;
  }

  getByCurrentCode(): Language {
    return this.getByCode(this.getCurrentCode());
  }

  getByCode(code: string): Language {
    const result = this.all().filter((p) => p.code === code)[0];
    return typeof result === 'undefined' ? null : result;
  }

  enabledLanguage(language): boolean {
    return this.disabledLanguages.indexOf(language.id) === -1;
  }
}
