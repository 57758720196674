import { Pipe, PipeTransform } from '@angular/core';
import { WeightConversionService } from '../../services/utility/weight-conversion.service';

@Pipe({
  name: 'isWeight',
})
export class WeightPipe implements PipeTransform {
  constructor(private weightConversionService: WeightConversionService) {}

  transform(gramAmount: number, isWeight: boolean = true) {
    return this.weightConversionService.convertWithUnits(gramAmount, isWeight);
  }
}
