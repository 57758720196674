import { Injectable } from '@angular/core';
import { ServerResponse } from '../../models/http/server-response';
import { ServerResponseCollection } from '../../models/http/server-response-collection';
import { IModel } from '../../models/model';
import { BaseHttpService } from './base-http.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

export class HttpService<T extends IModel> extends BaseHttpService {
  protected data = new BehaviorSubject<T[]>([]);
  constructor(http: HttpClient, protected key: string, apiUrl: string) {
    super(http, apiUrl);
  }

  reset() {
    this.assign([]);
  }

  watch(callback) {
    return this.data.subscribe(callback);
  }
  observe(): Observable<any> {
    return this.data;
  }
  all(): T[] {
    return this.data.value;
  }

  allAsync(reload = false): Promise<T[]> {
    return reload || this.all().length === 0
      ? this.reload()
      : Promise.resolve(this.all());
  }

  create(object: T | any): Promise<T> {
    return this.http
      .post<ServerResponse<T>>(this.getUrl(), object, this.getHttpGetOptions())
      .toPromise()
      .then(
        (result) => {
          return this.assignById(result.data);
        },
        (err) => {
          this.tryHandleError(err);
          throw err;
        }
      );
  }

  delete(object: T | number): Promise<boolean> {
    const id = this.getId(object);
    return this.http
      .delete(`${this.getUrl()}/${id}`)
      .toPromise()
      .then(
        () => {
          this.removeById(object);
          return true;
        },
        (err) => {
          this.tryHandleError(err);
          throw err;
        }
      );
  }

  get(id: number): T {
    return this.filterById(this.all(), id);
  }

  getAsync(id: number, reload = false): Promise<T> {
    return reload
      ? this.reloadOne(id)
      : this.allAsync().then((result) => {
          return this.filterById(result, id);
        });
  }

  reload(save = true): Promise<T[]> {
    return this.http
      .get<ServerResponseCollection<T>>(this.getUrl(), this.getHttpGetOptions())
      .toPromise()
      .then(
        (result) => {
          const objects = result.data || [];
          if (save) {
            return this.assign(objects);
          }
          return objects;
        },
        (err) => {
          this.tryHandleError(err);
          throw err;
        }
      );
  }

  reloadOne(object: T | number, save = true): Promise<T> {
    const id = this.getId(object);
    return this.http
      .get<ServerResponse<T>>(
        `${this.getUrl()}/${id}`,
        this.getHttpGetOptions()
      )
      .toPromise()
      .then(
        (result) => {
          const resultObject = result.data;
          if (save) {
            return this.assignById(resultObject, true);
          }

          return resultObject;
        },
        (err) => {
          this.tryHandleError(err);
          throw err;
        }
      );
  }

  update(object: T | number, data: T | any = null): Promise<T> {
    const id = this.getId(object);
    return this.http
      .put<ServerResponse<T>>(
        `${this.getUrl()}/${id}`,
        data === null ? object : data,
        this.getHttpGetOptions()
      )
      .toPromise()
      .then(
        (result) => {
          return this.assignById(result.data);
        },
        (err) => {
          this.tryHandleError(err);
          throw err;
        }
      );
  }

  assign(objects: T[]): T[] {
    // console.log('httpService Assign', objects);
    // reassign all new objects
    for (const o of objects) {
      this.assignById(o);
    }

    const existing = this.all().slice();
    // remove any that are stored locally but not present in the assign
    for (const o of existing) {
      // try to get this object from the local array
      if (!this.filterById(objects, this.getId(o))) {
        // object not found in new object array, remove it
        this.removeById(o);
      }
    }

    this.data.next(this.all());
    return this.all();
  }

  protected assignById(object: T, triggerWatch?: boolean): T {
    const id = this.getId(object);
    let index = this.findIndexById(id);
    if (index === -1) {
      index = this.all().push(object) - 1;
    } else {
      // remove any keys no longer present on object
      const existing = this.all()[index];
      for (const key in existing) {
        if (existing.hasOwnProperty(key) && !object.hasOwnProperty(key)) {
          delete existing[key];
        }
      }
      // _.merge(existing, object);
      _.mergeWith(existing, object, (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return srcValue;
        }
      });
    }

    if (triggerWatch) {
      this.data.next(this.all());
    }

    return this.all()[index];
  }

  protected filterById(results: T[], id: number): T {
    const result = results.filter((p) => this.getId(p) === id)[0];
    return typeof result === 'undefined' ? null : result;
  }

  protected findIndexById(id: number) {
    return this.all().findIndex((el) => this.getId(el) === id);
  }

  protected removeById(object: T | number) {
    const id = this.getId(object);
    const index = this.findIndexById(id);
    if (index === -1) {
      return;
    }

    this.all().splice(index, 1);
  }
}
