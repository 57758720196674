import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginData } from '../../models/auth/login-data';
import { LoginResult } from '../../models/auth/login-result';
import { ServerResponse } from '../../models/http/server-response';
import { User } from '../../models/timeline/user';
import { BaseHttpService } from '../http/base-http.service';
import { BaseAuthService } from './base-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseHttpService {
  constructor(http: HttpClient, protected baseAuthService: BaseAuthService) {
    super(http, 'api/auth');
  }

  isAuthenticated(): boolean {
    return this.baseAuthService.isAuthenticated();
  }

  getUser(): User {
    return this.baseAuthService.getUser();
  }

  getToken(): string {
    return this.baseAuthService.getToken();
  }

  assignFromLoginResult(result: LoginResult) {
    if (result.token) {
      this.assignToken(result.token);
    }
  }

  assignUser(user: User, fromLogin: boolean) {
    this.baseAuthService.assignUser(user);
  }

  assignToken(token: string) {
    this.baseAuthService.assignToken(token);
  }
}
