import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Household } from '../models/household/household';
import { HttpService } from './http/http.service';

@Injectable({ providedIn: 'root' })
export class HouseholdService extends HttpService<Household> {
  current = new BehaviorSubject<Household>(null);

  constructor(
    http: HttpClient,
  ) {
    super(http, 'households', 'api/household');

    this.httpParams = {
      'with[]': [
        'invites',
        'invites.creating',
        'invites.creating.profilePhoto',
        'invites.accepting',
        'invites.accepting.profilePhoto',
        'pets',
        'users',
        'users.user',
        'users.user.profilePhoto',
        'users.households',
        'timezone',
      ],
    };
  }

  getCurrent(): Household {
    return this.current.value === null ? null : this.current.value;
  }
}
