import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: false,
  apiUrl: 'https://app-api.devstable.surehub.io',
  environment: 'devstable',
  sentry: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBlb3qQsSqNXLxIVChjyRypmxWjlEHLNXc',
    authDomain: 'sure-petcare-dev.firebaseapp.com',
    databaseURL: 'https://sure-petcare-dev.firebaseio.com',
    projectId: 'sure-petcare-dev',
    storageBucket: 'sure-petcare-dev.appspot.com',
    messagingSenderId: '343486663681',
    appId: '1:343486663681:web:b2d387eaf3a9b6a324c400',
    measurementId: 'G-K3L08066XK',
  },
  features: {
    exampleFeature: false,
    displayEnv: true,
    changeEnv: true,
    felaquaTare: false,
    updatedPasswordRequirements: false,
  },
};
