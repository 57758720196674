import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { merge, of } from 'rxjs';
import { catchError, delay, filter, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export class TranslateHttpLoader implements TranslateLoader {
  baseUrl = '';

  constructor(
    private http: HttpClient,
    private endpoint: string,
  ) {
    this.baseUrl = `${environment.apiUrl}${endpoint}`;
  }

  /**
   * Gets the translations from the server
   *
   * @param lang
   * @returns
   */
  getTranslation(lang: string): any {
    console.log(
      `%c[Translation] Load translations`,
      'background:green; color: white;',
    );
    return merge(
      this.http.get(this.baseUrl, { params: { lang } }).pipe(
        tap((data) => {
          data['lastUpdated'] = Date.now();
          localStorage.setItem(`translation_${lang}`, JSON.stringify(data));
        }),
        catchError((error) =>
          of({
            ...JSON.parse(localStorage.getItem(`translation_${lang}`)),
            lastUpdated: Date.now(),
          }),
        ),
      ),
      of(JSON.parse(localStorage.getItem(`translation_${lang}`))).pipe(
        delay(1000),
        filter((data) => data !== null),
      ),
    ).pipe(
      filter((data) => data !== null),
      filter((data) => data.lastUpdated > Date.now() - 1000 * 60 * 60),
      take(1),
    );
  }
}
